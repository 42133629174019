import { GartnerFooter } from "gx-npm-ui";
import FileListComponent from "../../sections/file-list/file-list.component";
import styles from "./product-tab-view.styles.module.scss";
import FileDragAndDropUploadComponent from "../../sections/file-drag-and-drop-upload/file-drag-and-drop-upload.component";

const ProductTabViewComponent = () => {
  return (
    <section className={styles.productTabRoot}>
      <div className={styles.productTabContent}>
        <div className={styles.leftColumn}>
          <FileDragAndDropUploadComponent />
        </div>
        <div className={styles.rightColumn}>
          <FileListComponent />
        </div>
      </div>
      <GartnerFooter hasNavbar={false} />
    </section>
  );
};

export default ProductTabViewComponent;
