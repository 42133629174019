import { GartnerFooter } from "gx-npm-ui";
import FileHubHeaderComponent from "../../sections/file-hub-header/file-hub-header.component";
import FileListComponent from "../../sections/file-list/file-list.component";
import RecommendedCardsComponent from "../../sections/recommended-cards/recommended-cards.component";
import styles from "./stand-alone-view.styles.module.scss";
import { useScrolling } from "gx-npm-lib";
import classNames from "classnames";
import FileDragAndDropUploadComponent from "../../sections/file-drag-and-drop-upload/file-drag-and-drop-upload.component";

const StandAloneViewComponent = () => {
  const isScrolling = useScrolling();
  return (
    <section className={styles.standAloneRoot}>
      <FileHubHeaderComponent />
      <div className={classNames(styles.standAloneContent, isScrolling && styles.scroll)}>
        <div className={styles.sidebarTop}>
          <FileDragAndDropUploadComponent />
        </div>
        <div className={styles.mainColumn}>
          <FileListComponent />
        </div>
        <div className={styles.sidebarBottom}>
          <RecommendedCardsComponent />
        </div>
      </div>
      <GartnerFooter hasNavbar={false} />
    </section>
  );
};

export default StandAloneViewComponent;
