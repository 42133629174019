import { InitUserRole, useUserInitAccess, UUID } from "gx-npm-lib";
import { TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ACCEPTED_FILE_EXTENSIONS, MAX_FILE_MB_SIZE } from "../file-drag-and-drop-upload.constants";
import TextLinkV2Component from "./text-link-v2/text-link-v2.component";
import styles from "./upload-instructions.styles.module.scss";
import { GCOM_4299_docMgmtUpdates } from "../../../lib/feature-flags";

type UploadInstructionsProps = { onClickLink?: () => void };
const UploadInstructionsComponent = ({ onClickLink = () => {} }: UploadInstructionsProps) => {
  const { t } = useTranslation();
  const { initId = "" } = useParams<{ initId: UUID }>();
  const { role, hasLoadedAccess } = useUserInitAccess(initId);
  const isViewer = !hasLoadedAccess || role === InitUserRole.VIEWER;
  const FFGCOM4229 = useFeatureFlag(GCOM_4299_docMgmtUpdates);
  return (
    <Fragment>
      <TypographyComponent
        boldness="medium"
        color="carbon"
        element="h3"
        rootClassName={styles.instructionsHeader}
        styling="p3"
      >
        {t("Add documents")}
      </TypographyComponent>
      <div className={styles.instructionsSubTextOne}>
        <p>
          <TypographyComponent boldness="regular" element="span" styling="p3">
            {t("Drag and drop or")}
          </TypographyComponent>
          {` `}
          <TextLinkV2Component
            ariaLabel={t("upload documents")}
            disabled={isViewer}
            onClick={onClickLink}
            rootClassName={styles.instructionsLink}
            text={t("browse documents")}
          />
        </p>
      </div>
      <div className={styles.instructionsSubtextTwo}>
        <TypographyComponent
          boldness="regular"
          color="iron"
          element="span"
          rootClassName={styles.instructionsFileTypeTitle}
          styling="p4"
        >
          {t("Document types")}
        </TypographyComponent>
        <TypographyComponent
          boldness="regular"
          color="iron"
          element="span"
          rootClassName={styles.instructionsFileTypes}
          styling="p4"
        >
          ({ACCEPTED_FILE_EXTENSIONS})
        </TypographyComponent>
        <TypographyComponent color="iron" element="span" rootClassName={styles.instructionsSpacer} styling="p4">
          {`|`}
        </TypographyComponent>
        <TypographyComponent
          boldness="regular"
          color="iron"
          element="span"
          rootClassName={FFGCOM4229 ? styles.instructionsFileSizeFFGCOM4229 : styles.instructionsFileSize}
          styling="p4"
        >
          {`${t("Max size")} ${MAX_FILE_MB_SIZE}MB`}
        </TypographyComponent>
      </div>
    </Fragment>
  );
};

export default UploadInstructionsComponent;
