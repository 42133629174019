import classNames from "classnames";
import { TypographyComponent } from "gx-npm-ui";
import { Fragment, MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import emptyFileListImage from "./empty-file-list-image.svg";
import InfoOutlineIcon from "./info-outline-icon";

import { getAsyncRequest } from "gx-npm-lib";
import { useParams } from "react-router-dom";
import styles from "./file-list.styles.module.scss";

type FileMetaData = {
  id: string;
  fileName: string;
  fileType: string;
  createdDate: Date;
  owner: string;
};

const FileListComponent = () => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileMetaData[]>([]);
  const [_isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const { initId, initProdId } = useParams();

  const fileCount = files.length;

  useEffect(() => {
    if (!initId) {
      return;
    }
    (async () => {
      const url = initProdId
        ? `/api/v2/initiatives/${initId}/files/product/${initProdId}`
        : `/api/v2/initiatives/${initId}/files`;
      try {
        const response = await getAsyncRequest(url);
        if (response.status === 200 && Array.isArray(response.data.data.files)) {
          setFiles(response.data.data.files);
        } else {
          throw new Error();
        }
      } catch (error) {
        setHasError(true);
      } finally {
        // todo figure out loading screen
        setIsLoading(false);
      }
    })();
  }, [initId, initProdId]);

  const handleFQLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open(`https://www.gartner.com/`, "_blank", "noopener");
  };

  return (
    <div className={styles.fileListRoot}>
      <div className={styles.fileCount}>
        <TypographyComponent styling={"p3"} boldness={"medium"}>
          {t("Files")} ({fileCount})
        </TypographyComponent>
      </div>
      <div className={styles.body}>
        <div className={styles.tableHeader}>
          <TypographyComponent styling={"p4"} boldness={"medium"} rootClassName={styles.columnName} color={"iron"}>
            {t("Name")}
          </TypographyComponent>
          <TypographyComponent styling={"p4"} boldness={"medium"} rootClassName={styles.columnVendor} color={"iron"}>
            {t("Vendor")}
          </TypographyComponent>
          <TypographyComponent styling={"p4"} boldness={"medium"} rootClassName={styles.columnOwner} color={"iron"}>
            {t("Owner")}
          </TypographyComponent>
          <TypographyComponent styling={"p4"} boldness={"medium"} color={"iron"}>
            {t("Date added")}
          </TypographyComponent>
        </div>
        {hasError && "TODO ERROR COMPONENT"}
        {fileCount === 0 ? (
          <div className={styles.emptyState}>
            <img alt={"Empty file list"} src={emptyFileListImage} className={styles.emptyFile} />
            <TypographyComponent styling={"h4"} rootClassName={styles.emptyStateTitle}>
              {t("No files added")}
            </TypographyComponent>
            <TypographyComponent styling={"p3"} boldness={"regular"} rootClassName={styles.emptyStateDesc}>
              {t("Your team's file library is empty. Once files are uploaded, they'll appear here.")}
            </TypographyComponent>
          </div>
        ) : (
          // file component
          <Fragment />
        )}
      </div>
      <div className={styles.info}>
        <div className={classNames(styles.infoIcon)}>
          <InfoOutlineIcon />
        </div>
        <TypographyComponent
          color={"coal"}
          boldness={"regular"}
          rootClassName={classNames(styles.infoText)}
          styling="p5"
        >
          {t(
            "Files uploaded by vendors are not reviewed by Gartner Experts and do not necessarily represent the views or opinions of Gartner. Check out our FAQ on storing client documents "
          )}
          <a href="https://www.gartner.com" className={styles.infoLink} onClick={handleFQLinkClick}>
            {t("here.")}
          </a>
        </TypographyComponent>
      </div>
    </div>
  );
};

export default FileListComponent;
