import { ListSubheader, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import classNames from "classnames";
import { postAsyncRequest, useCaptureEventsV2, UUID } from "gx-npm-lib";
import {
  IconButton,
  ProductLogo,
  SnackbarBanner,
  TextLink,
  TooltipV2,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { Fragment, MouseEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { navigateToUrl } from "single-spa";
import { ClientEvent } from "../../../app.constants";
import { ProductDto } from "../file-list.types";
import ClearIcon from "./clear.icon";
import { excessiveDOMRectsOverlap } from "./product-select.lib";
import styles from "./product-select.styles.module.scss";
import { GCOM_4299_docMgmtUpdates } from "../../../lib/feature-flags";

type ProductSelectProps = {
  fileId: UUID;
  initProdId: UUID;
  isDisabled: boolean;
  isRowHovered: boolean;
  onChange: (initProdId: UUID) => void;
  onClose: () => void;
  onOpen: () => void;
  productsInEval: ProductDto[];
  productsNotInEval: ProductDto[];
};
const ProductSelectComponent = ({
  fileId,
  initProdId = "",
  isDisabled = false,
  isRowHovered = false,
  onChange = (_initProdId: UUID) => {},
  onClose = () => {},
  onOpen = () => {},
  productsInEval = [],
  productsNotInEval = [],
}: ProductSelectProps) => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const [hasProducts, setHasProducts] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOverlappingMenu, setIsOverlappingMenu] = useState(false);
  const [menuVerticalPosition, setMenuVerticalPosition] = useState<"bottom" | "top">("bottom");
  const clearIconRef = useRef<HTMLDivElement | null>(null);
  const menuListRef = useRef<HTMLUListElement | null>(null);
  const { initId = "", initProdId: paramsInitProdId = "" } = useParams<{ initId: UUID; initProdId: UUID }>();
  const captureEvents = useCaptureEventsV2();
  const FFGCOM4299 = useFeatureFlag(GCOM_4299_docMgmtUpdates);

  useEffect(() => {
    setHasProducts([...productsInEval, ...productsNotInEval].length > 0);
  }, [productsInEval, productsNotInEval]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isOpen || !clearIconRef.current || !menuListRef.current) {
        setIsOverlappingMenu(false);
        return;
      }
      const clearIconRect = clearIconRef.current.getBoundingClientRect();
      const menuListRect = menuListRef.current.getBoundingClientRect();
      const isOverlapping = excessiveDOMRectsOverlap(clearIconRect, menuListRect);
      setIsOverlappingMenu(isOverlapping);
    }, 0);
    return () => {
      clearTimeout(timer);
    };
  }, [isOpen, clearIconRef, menuListRef]);

  const updateProduct = async (newInitProdId: UUID) => {
    const url = `/api/v2/initiatives/${initId}/file/${fileId}/update`;
    const response = await postAsyncRequest(url, { initProductId: newInitProdId });
    if (response.status === 201) {
      const metaData = {
        initiativeId: initId,
        fileId,
        newInitProdId,
        oldInitProdId: initProdId,
        ...(paramsInitProdId ? { initProductId: paramsInitProdId } : {}),
      };
      captureEvents([{ eventType: ClientEvent.INITIATIVE_FILE_HUB_FILE_PRODUCT_UPDATED, metaData }]);
      onChange(newInitProdId);
    } else {
      setHasError(true);
    }
  };

  const handleChangeProduct = async (event: SelectChangeEvent<UUID>) => {
    if (!hasProducts) {
      return;
    }
    await updateProduct(event.target.value as UUID);
  };

  const handleClearProduct = async () => {
    setIsOpen(false);
    await updateProduct("");
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    const targetRect = event.currentTarget.getBoundingClientRect();
    setMenuVerticalPosition(targetRect.bottom + 420 > window.innerHeight ? "top" : "bottom");
    setIsOpen(true);
    onOpen();
  };

  const handleGoToVendorList = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigateToUrl(`/s/evaluation/${initId}/products`);
  };

  return (
    <Fragment>
      <Select
        className={classNames(styles.productSelectContainer, isOpen && styles.open, !isDisabled && styles.outline)}
        classes={{ outlined: styles.muiSelectOutlined, root: styles.muiSelectRoot }}
        defaultValue=""
        disabled={isDisabled}
        displayEmpty={true}
        IconComponent={() => null}
        MenuProps={{
          anchorOrigin: { horizontal: "left", vertical: menuVerticalPosition },
          classes: {
            list: styles.muiSelectMenuList,
            paper: classNames(
              styles.muiSelectMenuPaper,
              menuVerticalPosition === "top" && styles.reverseMarginTop,
              isOverlappingMenu && styles.overlapping
            ),
            root: styles.muiSelectMenuRoot,
          },
          MenuListProps: { ref: menuListRef },
          transformOrigin: { horizontal: "left", vertical: menuVerticalPosition === "top" ? "bottom" : "top" },
        }}
        onChange={handleChangeProduct}
        onClose={handleClose}
        onOpen={handleOpen}
        open={isOpen}
        renderValue={(selectedId: UUID) => {
          const product = [...productsInEval, ...productsNotInEval].find((prod) => prod.initProductId === selectedId);
          if (!product) {
            return (
              <TypographyComponent
                boldness="regular"
                rootClassName={classNames((!isRowHovered || isDisabled) && styles.hidden)}
                color={"iron"}
                styling="p4"
              >
                {FFGCOM4299 ? t("Select vendor") : t("Select Vendor")}
              </TypographyComponent>
            );
          }
          return (
            <TooltipV2
              deactivate={!isDisabled}
              placement="top"
              PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }}
              rootClassName={styles.productTooltip}
              title={t("Documents provided in a questionnaire response cannot be modified.")}
            >
              <div className={styles.selectedProductWrapper}>
                <ProductLogo imageHeight="24px" imageWidth="24px" logo={product.imageLoc} name={product.name} />
                <TypographyComponent
                  boldness="regular"
                  color="carbon"
                  rootClassName={styles.selectedProductName}
                  styling="p4"
                >
                  {product.name}
                </TypographyComponent>
                {isOpen && (
                  <div className={FFGCOM4299 ? styles.clearIcon : ""} ref={clearIconRef}>
                    <IconButton
                      ariaLabel={t("clear selection")}
                      className={styles.clearSelection}
                      focusBackgroundColor="transparent"
                      height={24}
                      hoverBackgroundColor="transparent"
                      onClick={handleClearProduct}
                      width={24}
                    >
                      <ClearIcon />
                    </IconButton>
                  </div>
                )}
              </div>
            </TooltipV2>
          );
        }}
        value={initProdId}
      >
        {!hasProducts && (
          <div className={styles.emptyListContainer}>
            <TypographyComponent boldness="regular" color="iron" styling="p3">
              {t("There are no vendors in the vendor list.")}
            </TypographyComponent>
            <TextLink
              onClick={handleGoToVendorList}
              rootClassName={styles.vendorsNavLink}
              text={t("Go to vendor list")}
            />
          </div>
        )}
        {hasProducts && (
          <ListSubheader classes={{ root: styles.muiListSubheaderRoot, gutters: styles.muiListSubheaderGutters }}>
            <TypographyComponent boldness="medium" color="coal" styling="p3">
              {t("Vendors in my evaluation")}
            </TypographyComponent>
          </ListSubheader>
        )}
        {hasProducts && productsInEval.length === 0 && (
          <div className={styles.noAvailableProducts}>
            <TypographyComponent boldness="regular" color="iron" styling="p4">
              {t("No available vendors")}
            </TypographyComponent>
          </div>
        )}
        {productsInEval.map((prod) => (
          <MenuItem key={prod.initProductId} classes={{ root: styles.muiMenuItemRoot }} value={prod.initProductId}>
            <ProductLogo imageHeight="24px" imageWidth="24px" logo={prod.imageLoc} name={prod.name} />
            <TypographyComponent boldness="medium" styling="p3">
              {prod.name}
            </TypographyComponent>
          </MenuItem>
        ))}
        {hasProducts && (
          <ListSubheader classes={{ root: styles.muiListSubheaderRoot, gutters: styles.muiListSubheaderGutters }}>
            <TypographyComponent boldness="medium" color="coal" styling="p3">
              {t("Other vendors")}
            </TypographyComponent>
          </ListSubheader>
        )}
        {hasProducts && productsNotInEval.length === 0 && (
          <div className={styles.noAvailableProducts}>
            <TypographyComponent boldness="regular" color="iron" styling="p4">
              {t("No available vendors")}
            </TypographyComponent>
          </div>
        )}
        {productsNotInEval.map((prod) => (
          <MenuItem key={prod.initProductId} classes={{ root: styles.muiMenuItemRoot }} value={prod.initProductId}>
            <ProductLogo imageHeight="24px" imageWidth="24px" logo={prod.imageLoc} name={prod.name} />
            <TypographyComponent boldness="medium" color="carbon" styling="p3">
              {prod.name}
            </TypographyComponent>
          </MenuItem>
        ))}
      </Select>
      <SnackbarBanner setIsOpen={() => setHasError(false)} isDefaultErrorMessage={true} isOpen={hasError} />
    </Fragment>
  );
};

export default ProductSelectComponent;
