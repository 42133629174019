import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagBooleanContainer, FeatureFlagBooleanOn, FeatureFlagProvider } from "gx-npm-ui";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GCOM_3787__fileHub } from "./lib/feature-flags";
import StandAloneViewComponent from "./views/stand-alone/stand-alone-view.component";
import ProductTabViewComponent from "./views/product-tab/product-tab-view.component";

const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <FeatureFlagBooleanContainer flagName={GCOM_3787__fileHub} strictMode={true}>
        <FeatureFlagBooleanOn>
          <BrowserRouter>
            <Routes>
              <Route path="/s/evaluation/:initId/files/all" element={<StandAloneViewComponent />} />
              <Route path="/s/evaluation/:initId/product/:initProdId/files" element={<ProductTabViewComponent />} />
            </Routes>
          </BrowserRouter>
        </FeatureFlagBooleanOn>
      </FeatureFlagBooleanContainer>
    </FeatureFlagProvider>
  );
};

export default Root;
