import classNames from "classnames";
import { postAsyncRequest, putAsyncRequest, UUID } from "gx-npm-lib";
import { FormEvent, useCallback, useEffect, useState, useRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styles from "./file-drag-and-drop-upload.styles.module.scss";
import { Button, Loader, TextLink, TypographyComponent } from "gx-npm-ui";
import { ExclamationCircledIcon } from "gx-npm-icons";

const ACCEPTED_FILE_TYPES = Object.freeze([
  "application/msword",
  "application/pdf",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/png",
  "text/plain",
  "video/mp4",
  "video/quicktime",
]);
const SIZE_LIMIT = 1024 * 1024 * 100; /* 100 MB */
type UploadFilesResponse = {
  data: { data: { uploads: Array<{ fileName: string; signedUrl: string; uploadId: UUID }> } };
  status: number;
};

const FileDragAndDropUploadComponent = () => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const dropRef = useRef<HTMLDivElement>(null);
  const [dragOver, setDragOver] = useState(false);
  const { initId = "" } = useParams<{ initId: UUID }>();
  const handleFileUpload = useCallback(
    (fileList: FileList | null) => {
      if (!fileList || fileList.length === 0) {
        return;
      }
      const files = Array.from(fileList).map((file) => ({
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
      }));
      if (files.some((file) => !ACCEPTED_FILE_TYPES.includes(file.fileType))) {
        setHasError(true);
        return;
      }
      if (files.some((file) => file.fileSize > SIZE_LIMIT)) {
        setHasError(true);
        return;
      }

      (async () => {
        setIsLoading(true);
        const url = `/api/v2/initiatives/${initId}/files/upload`;
        const response: UploadFilesResponse = await postAsyncRequest(url, { files });
        if (response.status !== 201 || !Array.isArray(response.data?.data?.uploads)) {
          setHasError(true);
          setIsLoading(false);
          return;
        }

        const readFileAsDataURL = (file: File): Promise<string> => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              if (typeof reader.result === "string") {
                resolve(reader.result);
              } else {
                reject();
              }
            };
            reader.onerror = () => reject();
            reader.readAsDataURL(file);
          });
        };

        const { uploads } = response.data.data;
        for (let i = 0; i < fileList.length; i++) {
          const file = fileList[i];
          const uploadData = uploads.find((upload) => upload.fileName === file.name);
          if (!uploadData) {
            // todo should this error? and if yes, should the try start here?
            continue;
          }
          const { signedUrl, uploadId } = uploadData;
          try {
            const dataURL = await readFileAsDataURL(file);
            const binary = window.atob(dataURL.split(",")[1]);
            const array = Array.from(binary, (char) => char.charCodeAt(0));
            const blobData = new Blob([new Uint8Array(array)], { type: file.type });
            const s3response = await putAsyncRequest(signedUrl, blobData, { headers: { "Content-Type": file.type } });
            if (s3response.status !== 200) {
              throw new Error();
            }
            const confirmationUrl = `/api/v2/initiatives/${initId}/file/upload/${uploadId}/upload-complete`;
            const confirmationResponse = await postAsyncRequest(confirmationUrl);
            if (confirmationResponse.status !== 201) {
              throw new Error();
            }
          } catch (error) {
            setIsLoading(false);
            setHasError(true);
            // todo should this break or continue?
            break;
          }
        }
        setIsLoading(false);
      })();
    },
    [initId, setHasError, setIsLoading]
  );

  useEffect(() => {
    const currentRef = dropRef?.current;
    if (!currentRef) {
      return;
    }
    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(false);
      handleFileUpload(e.dataTransfer?.files || null);
    };

    currentRef.addEventListener("drop", handleDrop);
    return () => {
      currentRef.removeEventListener("drop", handleDrop);
    };
  }, [dropRef, handleFileUpload]);

  useEffect(() => {
    const currentRef = dropRef?.current;
    if (!currentRef) {
      return;
    }
    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(true);
    };

    const handleDragEnter = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(true);
    };

    const handleDragLeave = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(false);
    };

    currentRef.addEventListener("dragenter", handleDragEnter);
    currentRef.addEventListener("dragleave", handleDragLeave);
    currentRef.addEventListener("dragover", handleDragOver);
    return () => {
      currentRef.removeEventListener("dragenter", handleDragEnter);
      currentRef.removeEventListener("dragleave", handleDragLeave);
      currentRef.removeEventListener("dragover", handleDragOver);
    };
  }, [dropRef]);

  const handleFileChange = (e: FormEvent<HTMLInputElement>) => {
    handleFileUpload((e.target as HTMLInputElement)?.files);
  };
  //todo accessibility check once fully-functioning for whatever the word is for non-accessibility
  return (
    <div aria-label={t("file drop area")} ref={dropRef} className={classNames(dragOver && styles.dragOver)}>
      <div className={classNames(styles.uploadInputRoot, hasError && styles.hasError)}>
        {isLoading && (
          <Fragment>
            <Loader rootClassName={styles.loader} />
            <TypographyComponent rootClassName={styles.uploadingText} boldness="medium" styling="p3" color="carbon">
              {t("Uploading your file")}
            </TypographyComponent>
          </Fragment>
        )}
        {hasError && !isLoading && (
          <Fragment>
            <ExclamationCircledIcon />
            <TypographyComponent rootClassName={styles.failedText} boldness="medium" color="poisonCherry" styling="p3">
              {t("File Upload failed")}
            </TypographyComponent>
            <TypographyComponent boldness="regular" color="iron" styling="p4">
              {t("Check file types")}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.fileTypes} boldness="regular" color="iron" styling="p4">
              ({t("doc, docx, ppt, pptx, xls, xlsx, pdf, txt.")})
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.maxText} boldness="regular" color="iron" styling="p4">
              {t("Max size 100MB")}
            </TypographyComponent>
            {<Button onClick={() => setHasError(false)}>{t("Reset")}</Button>}
          </Fragment>
        )}
        {!isLoading && !hasError && (
          <Fragment>
            <TypographyComponent rootClassName={styles.addText} boldness="medium" color="carbon" styling="p3">
              {t("Add files")}
            </TypographyComponent>
            <div className={styles.dragWrapper}>
              <TypographyComponent rootClassName={styles.dragText} boldness="regular" styling="p3">
                {t("Drag and drop or")}
              </TypographyComponent>
              <TextLink ariaLabel={t("upload files")} onClick={handleFileUpload} text={t("choose a file")} />
            </div>
            <TypographyComponent boldness="regular" color="iron" styling="p4">
              {t("File Types")}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.fileTypes} boldness="regular" color="iron" styling="p4">
              ({t("doc, docx, ppt, pptx, xls, xlsx, pdf, txt.")})
            </TypographyComponent>
            <TypographyComponent boldness="regular" color="iron" styling="p4">
              {t("Max size 100MB")}
            </TypographyComponent>
          </Fragment>
        )}
      </div>
      <input
        accept=".doc,.docx,.pdf,.ppt,.pptx,.xls,.xlsx,.txt,.mov,.mp4,.jpeg,.png"
        multiple
        onChange={handleFileChange}
        style={{ display: "none" }}
        type="file"
      />
    </div>
  );
};

export default FileDragAndDropUploadComponent;
