import classNames from "classnames";
import {
  InitUserRole,
  postAsyncRequest,
  putAsyncRequest,
  useCaptureEventsV2,
  useUserInitAccess,
  UUID,
} from "gx-npm-lib";
import { useFeatureFlag } from "gx-npm-ui";
import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AppCustomEvent, ClientEvent, RejectedUploadReason } from "../../app.constants";
import { GCOM_4364__minFileSize } from "../../lib/feature-flags";
import UploadErrorComponent from "./upload-error/upload-error.component";
import UploadInstructionsComponent from "./upload-instructions/upload-instructions.component";
import UploadProcessingComponent from "./upload-processing/upload-processing.component";
import {
  ACCEPTED_FILE_EXTENSIONS,
  MAX_FILE_MB_SIZE,
  MAX_FILE_NAME_CHARACTER_LIMIT,
  MULTI_PART_FILE_THRESHOLD_BYTE_SIZE,
} from "./file-drag-and-drop-upload.constants";
import styles from "./file-drag-and-drop-upload.styles.module.scss";

const ACCEPTED_FILE_TYPES = Object.freeze([
  "application/msword",
  "application/pdf",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/png",
  "text/plain",
  "video/mp4",
  "video/quicktime",
]);
type UploadFilesResponse = {
  data: {
    data: {
      uploadsSignedUrls: Array<{ fileId: UUID; fileName: string; signedUrls: string[]; multiPartUploadId?: string }>;
    };
  };
  status: number;
};
const MIN_FILE_BYTE_SIZE = 1;
const MAX_FILE_BYTE_SIZE = 1024 * 1024 * MAX_FILE_MB_SIZE;

const FileDragAndDropUploadComponent = () => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState<boolean>(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { initId = "", initProdId = "" } = useParams<{ initId: UUID; initProdId: UUID }>();
  const dropRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const captureEvents = useCaptureEventsV2();
  const isMinFileSizeFFON = useFeatureFlag(GCOM_4364__minFileSize);
  const { hasLoadedAccess, role } = useUserInitAccess(initId);
  const isViewer = hasLoadedAccess && role === InitUserRole.VIEWER;

  const handleFileUpload = useCallback(
    (fileList: FileList | null) => {
      if (!fileList || fileList.length === 0) {
        return;
      }
      const metaDataBase = { initiativeId: initId, ...(initProdId ? { initProductId: initProdId } : {}) };
      if (fileList.length > 1) {
        const metaData = { ...metaDataBase, count: fileList.length };
        captureEvents([{ eventType: ClientEvent.INITIATIVE_FILE_HUB_BULK_UPLOAD, metaData }]);
      }
      const files = Array.from(fileList).map((file) => ({
        fileName: file.name || "",
        fileSize: file.size || 0,
        fileType: file.type || "",
      }));
      if (files.some((file) => !ACCEPTED_FILE_TYPES.includes(file.fileType))) {
        setHasError(true);
        const metaData = { ...metaDataBase, reason: RejectedUploadReason.FILE_TYPE_NOT_ALLOWED };
        captureEvents([{ eventType: ClientEvent.INITIATIVE_FILE_HUB_REJECTED_UPLOAD, metaData }]);
        return;
      }
      if (isMinFileSizeFFON && files.some((file) => file.fileSize < MIN_FILE_BYTE_SIZE)) {
        setHasError(true);
        const metaData = { ...metaDataBase, reason: RejectedUploadReason.FILE_SIZE_FALLS_SHORT_OF_MINIMUM };
        captureEvents([{ eventType: ClientEvent.INITIATIVE_FILE_HUB_REJECTED_UPLOAD, metaData }]);
        return;
      }
      if (files.some((file) => file.fileSize > MAX_FILE_BYTE_SIZE)) {
        setHasError(true);
        const metaData = { ...metaDataBase, reason: RejectedUploadReason.FILE_SIZE_EXCEEDED_LIMIT };
        captureEvents([{ eventType: ClientEvent.INITIATIVE_FILE_HUB_REJECTED_UPLOAD, metaData }]);
        return;
      }
      if (files.some((file) => file.fileName.length > MAX_FILE_NAME_CHARACTER_LIMIT)) {
        setHasError(true);
        const metaData = { ...metaDataBase, reason: RejectedUploadReason.FILE_NAME_LENGTH_EXCEEDED_LIMIT };
        captureEvents([{ eventType: ClientEvent.INITIATIVE_FILE_HUB_REJECTED_UPLOAD, metaData }]);
        return;
      }
      const payload = initProdId !== "" ? { files, initProductId: initProdId } : { files };

      (async () => {
        setHasError(false);
        setIsProcessing(true);
        const url = `/api/v3/initiatives/${initId}/files/upload`;
        const response: UploadFilesResponse = await postAsyncRequest(url, payload);
        if (response.status !== 201 || !Array.isArray(response.data?.data?.uploadsSignedUrls)) {
          setHasError(true);
          setIsProcessing(false);
          return;
        }

        const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              if (reader.result instanceof ArrayBuffer) {
                resolve(reader.result);
              } else {
                reject(new Error("Failed to read file as ArrayBuffer"));
              }
            };
            reader.onerror = () => reject(new Error("File reading error"));
            reader.readAsArrayBuffer(file);
          });
        };

        const { uploadsSignedUrls } = response.data.data;
        for (let fileIndex = 0; fileIndex < fileList.length; fileIndex++) {
          const file = fileList[fileIndex];
          const uploadData = uploadsSignedUrls.find((upload) => upload.fileName === file.name);
          try {
            if (!uploadData) {
              throw new Error();
            }
            const { fileId, signedUrls, multiPartUploadId } = uploadData;
            const arrayBuffer = await readFileAsArrayBuffer(file);
            const blobData = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });
            if (file.size <= MULTI_PART_FILE_THRESHOLD_BYTE_SIZE) {
              const s3response = await putAsyncRequest(signedUrls[0], blobData, {
                headers: { "Content-Type": file.type },
              });
              if (s3response.status !== 200) {
                throw new Error();
              }
              const confirmationUrl = `/api/v2/initiatives/${initId}/file/${fileId}/complete`;
              const confirmationResponse = await postAsyncRequest(confirmationUrl);
              if (confirmationResponse.status !== 201) {
                throw new Error();
              }
            } else {
              const chunkSize = MULTI_PART_FILE_THRESHOLD_BYTE_SIZE;
              const numberOfParts = Math.ceil(file.size / chunkSize);
              const completedParts = [];
              for (let partIndex = 0; partIndex < numberOfParts; partIndex++) {
                const start = partIndex * chunkSize;
                const end = Math.min(start + chunkSize, file.size);
                const blob = blobData.slice(start, end);
                const uploadResponse = await putAsyncRequest(signedUrls[partIndex], blob, {
                  headers: { "Content-Type": file.type },
                });
                if (uploadResponse.status !== 200) {
                  throw new Error();
                }
                completedParts.push({ ETag: uploadResponse.headers.get("etag"), PartNumber: partIndex + 1 });
              }

              const confirmationUrl = `/api/v2/initiatives/${initId}/file/${fileId}/complete-multi-part`;
              const confirmationPayload = { completedParts, multiPartUploadId };
              const confirmationResponse = await postAsyncRequest(confirmationUrl, confirmationPayload);
              if (confirmationResponse.status !== 201) {
                throw new Error();
              }
            }
            const detail = { fileId, fileName: file.name, initProdId };
            window.dispatchEvent(new CustomEvent(AppCustomEvent.SUCCESSFUL_FILE_UPLOADED, { detail }));
            const metaData = { ...metaDataBase, fileId };
            captureEvents([{ eventType: ClientEvent.INITIATIVE_FILE_HUB_SUCCESSFUL_UPLOAD, metaData }]);
          } catch (error) {
            setIsProcessing(false);
            setHasError(true);
            break;
          }
        }
        setIsProcessing(false);
      })();
    },
    [captureEvents, initId, initProdId, isMinFileSizeFFON, setHasError, setIsProcessing]
  );

  useEffect(() => {
    const currentRef = dropRef?.current;
    if (!currentRef || isViewer) {
      return;
    }
    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDraggingOver(false);
      handleFileUpload(e.dataTransfer?.files || null);
    };

    currentRef.addEventListener("drop", handleDrop);
    return () => {
      currentRef.removeEventListener("drop", handleDrop);
    };
  }, [dropRef, handleFileUpload, isViewer]);

  useEffect(() => {
    const currentRef = dropRef?.current;
    if (!currentRef || isViewer) {
      return;
    }
    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDraggingOver(true);
    };

    const handleDragEnter = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDraggingOver(true);
    };

    const handleDragLeave = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDraggingOver(false);
    };

    currentRef.addEventListener("dragenter", handleDragEnter);
    currentRef.addEventListener("dragleave", handleDragLeave);
    currentRef.addEventListener("dragover", handleDragOver);
    return () => {
      currentRef.removeEventListener("dragenter", handleDragEnter);
      currentRef.removeEventListener("dragleave", handleDragLeave);
      currentRef.removeEventListener("dragover", handleDragOver);
    };
  }, [dropRef, isViewer]);

  return (
    <div
      aria-label={t("document drop area")}
      className={classNames(
        styles.uploadRoot,
        hasError && styles.error,
        isDraggingOver && styles.dragging,
        isProcessing && styles.processing
      )}
      ref={dropRef}
    >
      <div className={styles.uploadContentRoot}>
        {isProcessing && <UploadProcessingComponent />}
        {!isProcessing && hasError && <UploadErrorComponent onClickReset={() => setHasError(false)} />}
        {!isProcessing && !hasError && <UploadInstructionsComponent onClickLink={() => inputRef.current?.click()} />}
      </div>
      <input
        accept={ACCEPTED_FILE_EXTENSIONS}
        className={styles.inputField}
        disabled={isViewer}
        multiple={true}
        onChange={(e: FormEvent<HTMLInputElement>) => handleFileUpload((e.target as HTMLInputElement)?.files)}
        ref={inputRef}
        type="file"
      />
    </div>
  );
};

export default FileDragAndDropUploadComponent;
